<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage Users" />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
        <div>
          <md-button class="md-primary" :to="{ name: 'Create User' }">
            Add New User
          </md-button>
        </div>
      </div>
      <md-table
        v-model="users.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4">
          <div class="md-toolbar-section-start">
            <md-button class="md-primary button-icon" disabled>
              Download Excel
              <img src="@/assets/svg/excel.svg" />
            </md-button>
          </div>
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" />
            </md-field>
            <md-menu md-size="medium" md-align-trigger>
              <md-button
                md-menu-trigger
                class="md-primary button-icon"
                disabled
              >
                Filter
                <img src="@/assets/svg/filter.svg" />
              </md-button>

              <md-menu-content>
                <md-menu-item>Approved</md-menu-item>
                <md-menu-item>Pending</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="S/N" md-numeric>{{ item.id }}</md-table-cell>
          <md-table-cell md-label="Phone number"
            >{{ item.phone_ext }}{{ item.phone }}</md-table-cell
          >
          <md-table-cell md-label="Fullname">{{
            item.first_name + " " + item.last_name
          }}</md-table-cell>
          <md-table-cell md-label="Email">{{ item.email }}</md-table-cell>
          <md-table-cell md-label="Role">
            <div class="badge">User</div>
            <div
              v-for="(role, i) in item.roles"
              :key="i + 'role'"
              class="badge"
            >
              {{ role.display_name }}
            </div>
          </md-table-cell>
          <!--<md-table-cell md-label="Status">
            <div
              class="badge"
              :class="{
                success: item.approval_status == 'approved',
                danger: item.approval_status == 'disapproved',
              }"
            >
              {{ item.approval_status }}
            </div></md-table-cell
          > -->
          <md-table-cell md-label="Actions">
            <md-menu md-size="medium" md-align-trigger>
              <md-button :disabled="updating" md-menu-trigger class="bggreen"
                >Menu</md-button
              >

              <md-menu-content>
                <md-menu-item @click="setUser(item)">Preview</md-menu-item>
                <div v-if="hasPermission">
                  <router-link
                    tag="md-menu-item"
                    :to="{ name: 'Edit User Info', params: { id: item.id } }"
                  >
                    Edit
                  </router-link>
                  <md-menu-item @click="deleteUser(item.id)"
                    >Delete</md-menu-item
                  >
                  <md-menu-item @click="loginAs(item.id, item.first_name)"
                    >Login as {{ item.first_name }}
                  </md-menu-item>
                </div>
              </md-menu-content>
            </md-menu>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="users.mdPage"
        :records="users.mdCount"
        :per-page="users.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>

    <md-dialog :md-active.sync="show">
      <md-dialog-title>User Details</md-dialog-title>
      <div class="cancel-modal">
        <i class="material-icons" @click="show = false">close</i>
      </div>

      <div class="modal-contain" v-if="user">
        <div class="item">
          <strong>First name</strong>
          <span> {{ user.first_name }}</span>
        </div>
        <div class="item">
          <strong>Last name</strong>
          <span> {{ user.last_name }}</span>
        </div>
        <div class="item">
          <strong>Email Address</strong>
          <span v-html="user.email"> </span>
        </div>
        <div class="item">
          <strong>Phone Number</strong>
          <a :href="`tel:${user.phone_ext + user.phone}`">
            {{ user.phone_ext + user.phone }}</a
          >
        </div>

        <div class="item">
          <strong>Date Created</strong>
          <span> {{ user.created_at | formatDate }}</span>
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import axios from "axios";
const { mapActions, mapGetters } = createNamespacedHelpers("user");
export default {
  name: "User",
  data() {
    return {
      updating: false,
      deleting: false,
      search: "",
      show: false,
      time: null,
      user: null,
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },

  methods: {
    ...mapActions(["getUsers", "loginAsUser"]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getUsers(data);
    },
    async submitSearch() {
      await this.getUsers({ keyword: this.search });
    },

    setUser(user) {
      this.show = true;
      this.user = user;
    },
    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
    async loginAs(id, name) {
      const c = confirm(`Are you sure you want to login as ${name}?`);

      if (c) {
        const { token, redirect_url } = await this.loginAsUser(id);
        window.open(redirect_url, "_blank");
      }
    },
    async deleteUser(id) {
      const c = confirm(`Are you sure? This can't be reversed`);
      if (c) {
        alert();
      }
    },
    async validate() {
      await axios.post("http://127.0.0.1:8000/api/admin/auth/validate", {
        code: "2652|ldUWo3fGaIvWobEcBBK2qItY7qXR5NNJb9bhybVw",
      });
    },
  },
  created() {},
  mounted() {
    this.getUsers();
  },
  computed: {
    ...mapGetters(["users", "loading"]),
    hasPermission() {
      return this.$store.getters["auth/has_module_permission"]("crud");
    },
  },
};
</script>

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}
</style>
